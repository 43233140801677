import { StyleSheet } from "@react-pdf/renderer";
import { Colors } from "../../../../../styles/Theme";

const styles = StyleSheet.create({
  ecowaardeIcon: {
    position: "absolute",
    width: 120,
    height: 80,
    top: 32,
    left: 6,
  },
  mainPage: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  page: {
    padding: 30,
    color: "#2B2D34",
    position: "relative",
  },
  titleContainer: {
    height: 500,
    position: "absolute",
    top: 188,
    left: 40,
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingBottom: 160,
  },
  title: {
    fontSize: 20,
    textAlign: "left",

    fontWeight: 700,
    color: "white",
    marginLeft: 25,
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 14,

    fontWeight: 300,
    color: "white",
    marginLeft: 25,
    width: 300,
  },
  homeImage: {
    position: "absolute",
    height: 480,
    // top: -360,
    // left: 70
  },
  secondTitle: {
    color: "#32aed7",
    fontSize: 10,

    fontWeight: 600,
  },
  randomSvgContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  randomSvg: {
    width: 434,
    height: 407,
  },
  content: {
    position: "absolute",
    bottom: 35,
    left: 35,
    width: 520,
  },
  link: {
    alignSelf: "flex-end",
    color: "#32aed7",

    fontWeight: 200,
  },
  heading: {
    fontSize: 18,
    marginBottom: 40,
    marginTop: 40,
    color: "#32aed7",
    letterSpacing: "0.4pt",
  },
  question: {
    marginTop: 32,
    marginBottom: 8,
    fontSize: 14,

    fontWeight: 500,
  },
  text: {
    fontSize: 14,
    lineHeight: "1.3pt",

    fontWeight: 200,
  },
  logoContainer: {
    marginTop: 40,
  },
  logo: {
    width: 120,
  },
  headerImage: {
    position: "absolute",
    left: -15,
    top: 28,
    height: 45,
  },
  headerContainer: {
    position: "relative",
  },

  table: {
    width: 500,
    border: 1,
    borderColor: "#42ba7a",
    marginVertical: 20,
  },
  tableHeader: {
    backgroundColor: "#42ba7a",
    color: "white",
    paddingLeft: 10,
    padding: 10,
    fontSize: 11,

    fontWeight: 600,
    flexDirection: "row",
    borderBottomWidth: 1,
  },
  innerTable: {
    flexDirection: "row",
    alignItems: "center",
    padding: 4,
    paddingLeft: 10,
    fontSize: 12,

    fontWeight: 200,
  },
  tableRowHeader: {
    // width: 150,
    fontSize: 12,

    fontWeight: 500,
  },
  innerTable_2: {
    flexDirection: "column",
    paddingLeft: 0,
    padding: 0,
  },
  tableRowContent: {
    width: "100%",
    borderBottom: 1,
    borderBottomColor: "#42ba7a",
    paddingVertical: 10,
    marginLeft: 0,
    paddingLeft: 0,
    textAlign: "center",
  },
  energyLabel: {
    flexDirection: "row",

    // justifyContent: 'space-between',
    alignItems: "center",
    fontSize: 16,

    fontWeight: 200,
    marginBottom: 14,
    marginLeft: 20,
    marginRight: 20,
  },
  energyLabel_2: {
    width: 200,
    backgroundColor: "#F1F5F7",
    fontSize: 10,
    flexDirection: "row-reverse",
    padding: 10,
    borderRadius: 10,
    margin: 0,
    justifyContent: "space-between",
  },
  ecoLabelImage: {
    position: "absolute",
    height: 150,
    width: 130,
    left: -23,
    top: -10,
  },
  ecoLabelImage_2: {
    position: "absolute",
    height: 60,
    width: 50,
    top: -10,
    left: -8,
  },
  energyHeading: {
    fontSize: 26,

    fontWeight: 600,
    marginBottom: 14,
  },
  energyItemIcon: {
    marginRight: 5,
    width: 30,
  },
  energyItem: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 12,
    marginTop: 12,
  },
  energyItemTitle: {
    fontSize: 10,

    fontWeight: 600,
    marginBottom: 8,
  },
  energyItem_2: {
    marginTop: 6,
    fontSize: 10,
  },
  energyItemTitle_2: {
    marginBottom: 4,
    fontSize: 8,
  },
  energyLabelLetter: {
    fontSize: 105,

    fontWeight: 600,
    color: "#fff",
    marginLeft: 140,
    marginTop: 20,
  },
  world: {
    width: 260,
    marginLeft: 60,
    padding: 40,
    backgroundColor: "#F1F5F7",
    borderRadius: 10,
    fontSize: 18,
    lineHeight: "1.3pt",
  },

  copyright: {
    position: "absolute",
    bottom: 10,
    fontSize: 10,
    width: 600,
    textAlign: "center",

    fontWeight: 200,
  },
  btn: {
    margin: "1rem 0",
    padding: "0.7rem",
    borderRadius: "24px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "12px",
    transition: "transform 0.3s ease",
    maxWidth: "230px",
    width: "220px",
    cursor: "pointer",
    color: "white",
    textDecoration: "none",
    backgroundColor: Colors.main.green,
  },
  mainTitle: {
    fontSize: 48,

    fontWeight: 700,
    color: "#00A651",
    lineHeight: 1,
  },
  subtitle: {
    fontSize: 16,

    fontWeight: 400,
    color: "#333333",
    marginTop: 10,
  },
  houseImageContainer: {
    position: "absolute",
    bottom: -130,
    right: -120,
    width: 591,
    height: 637,
  },
  houseImageContainer_2: {
    position: "absolute",
    bottom: -200,
    right: -120,
    width: 591,
    height: 637,
  },
  houseImage: {
    width: "100%",
  },
  addressContainer: {
    position: "absolute",
    bottom: 40,
    left: 20,
  },
  addressLabel: {
    fontSize: 12,

    fontWeight: 600,
    color: "#FF6B00",
    marginBottom: 5,
  },
  addressText: {
    fontSize: 16,

    fontWeight: 600,
    color: "#333333",
  },
  addressCity: {
    fontSize: 16,

    fontWeight: 600,
    color: "#FF6B00",
  },

  // Add these to your existing styles object
  pageTitle: {
    fontSize: 24,

    fontWeight: 700,
    color: "#00A651",
    marginBottom: 20,
  },
  pageText: {
    fontSize: 12,

    fontWeight: 400,
    color: "#333333",
    marginBottom: 10,
  },
  bulletPoints: {
    marginLeft: 20,
    marginBottom: 10,
  },
  bulletPoint: {
    fontSize: 12,

    fontWeight: 400,
    color: "#333333",
  },
  section: {
    marginTop: 20,
  },
  sectionTitle: {
    fontSize: 16,

    fontWeight: 600,
    color: "#00A651",
    marginBottom: 10,
  },
  sectionText: {
    fontSize: 12,

    fontWeight: 400,
    color: "#333333",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 40,
    right: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerText: {
    fontSize: 10,

    fontWeight: 400,
    color: "#333333",
  },
  footerLogo: {
    width: 60,
  },
  propertyDetailsContainer: {
    marginBottom: 20,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 30,
    marginBottom: 5,
  },
  detailGrid: {
    flexDirection: "column",
    gap: 16,
    flexWrap: "wrap",
    marginBottom: 5,
  },
  detailLabel: {
    fontSize: 12,

    fontWeight: 400,
    color: "#666666",
  },
  detailValue: {
    fontSize: 12,

    fontWeight: 600,
    color: "#333333",
    textOverflow: "ellipsis",
  },
  imageContainer: {
    marginBottom: 20,
  },
  imageWrapper: {
    position: "relative",
  },
  buildingPhoto: {
    width: "100%",
    height: 200,
    objectFit: "cover",
  },
  mapImage: {
    width: "100%",
    height: 200,
    objectFit: "cover",
  },
  // imageCaption: {
  //   position: 'absolute',
  //   bottom: 10,
  //   left: 10,
  //   backgroundColor: 'rgba(255, 255, 255, 0.7)',
  //   padding: '5px 10px',
  //   fontSize: 10,
  //   fontFamily: 'WorkSans',
  //   fontWeight: 400,
  //   color: '#333333',
  // },
  investmentContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  investmentItem: {
    flexDirection: "column",
    marginBottom: 10,
  },
  // investmentLabel: {
  //   fontSize: 14,
  //   fontFamily: 'WorkSans',
  //   fontWeight: 400,
  //   color: '#333333',
  // },
  investmentValue: {
    fontSize: 18,

    fontWeight: 600,
    marginLeft: 20,
  },
  labelContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
  },
  labelItem: {
    alignItems: "center",
  },
  labelTitle: {
    fontSize: 12,

    fontWeight: 400,
    color: "#666666",
    marginBottom: 5,
  },
  labelBox: {
    width: 60,
    height: 60,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  labelText: {
    fontSize: 32,

    fontWeight: 700,
    color: "#FFFFFF",
  },
  labelArrow: {
    marginTop: 20,
  },
  labelArrowText: {
    fontSize: 24,
    color: "#666666",
  },
  labelA: { backgroundColor: "#00A651" },
  "labelA+": { backgroundColor: "#00A651" },
  "labelA++": { backgroundColor: "#00A651" },
  "labelA+++": { backgroundColor: "#00A651" },
  "labelA++++": { backgroundColor: "#00A651" },
  labelB: { backgroundColor: "#50B848" },
  labelC: { backgroundColor: "#B0D136" },
  labelD: { backgroundColor: "#FECC00" },
  labelE: { backgroundColor: "#F9A11B" },
  labelF: { backgroundColor: "#E63323" },
  labelG: { backgroundColor: "#E1261C" },
  energyInfoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  energyInfoLeft: {
    flex: 1,
  },
  energyInfoRight: {
    alignItems: "center",
  },
  energyInfoTitle: {
    fontSize: 14,

    fontWeight: 600,
    marginBottom: 10,
  },
  energyInfoItem: {
    fontSize: 12,

    marginBottom: 5,
  },
  measuresTable: {
    borderWidth: 1,
    borderColor: "#00A651",
  },
  tableHeaderText: {
    color: "white",
    fontSize: 14,

    fontWeight: 600,
    flex: 1,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#00A651",
  },
  tableCell: {
    flex: 1,
    padding: 8,
    fontSize: 12,

    fontWeight: 500,
    alignItems: "center",
    flexDirection: "row",
  },
  glossaryContainer: {
    marginTop: 10,
  },
  glossaryItem: {
    marginBottom: 10,
  },
  glossaryTerm: {
    fontSize: 12,

    fontWeight: 600,
    color: "#333333",
  },
  glossaryDefinition: {
    fontSize: 12,

    fontWeight: 400,
    color: "#333333",
    marginLeft: 20,
  },
  recommendationContainer: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#00A651",
    padding: 15,
  },
  recommendationHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  recommendationIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  recommendationTitle: {
    fontSize: 18,

    fontWeight: 600,
    color: "#00A651",
  },
  recommendationText: {
    fontSize: 12,

    marginBottom: 10,
  },
  recommendationPoints: {
    marginLeft: 20,
    marginBottom: 10,
  },
  recommendationPoint: {
    fontSize: 12,

    marginBottom: 5,
  },
  installationCost: {
    fontSize: 14,

    fontWeight: 600,
    marginTop: 5,
  },
  bulletPointContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 5,
  },
  bulletIcon: {
    width: 12,
    height: 12,
    marginRight: 8,
    marginTop: 4,
  },
  bulletPointText: {
    flex: 1,
    fontSize: 10,
    lineHeight: 1.5,
  },
  imageCaptionContainer: {
    position: "absolute",
    bottom: 10,
    left: 10,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: "5px 10px",
    flexDirection: "row",
    alignItems: "center",
  },
  imageCaption: {
    fontSize: 10,

    fontWeight: 400,
    color: "#333333",
    marginLeft: 5,
  },
  // Add these new styles to your existing styles object
  investmentIconContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  investmentLabel: {
    fontSize: 14,

    fontWeight: 400,
    color: "#333333",
    marginLeft: 10,
  },
  measureImage: {
    width: 20,
    height: 20,
  },
});

export default styles;
