import React, { FC } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import EnergyLabelPDF from "./EnergyLabelPDF";
import { IoMdDownload } from "react-icons/io";
import Button from "../../../../../components/Button";

interface Props {
  data: any;
  buildingPhoto?: string;
  map?: string;
}

const EnergyLabelResult: FC<Props> = ({
  data,
  buildingPhoto = "https://via.placeholder.com/400x300?text=No+Building+Photo",
  map = "https://via.placeholder.com/400x300?text=No+Map+Available",
}) => {
  return (
    <Button type="button" className="flex items-center bg-primary" size="xl">
      <PDFDownloadLink
        document={
          <EnergyLabelPDF data={data} buildingPhoto={buildingPhoto} map={map} />
        }
        fileName={`Energielabel_rapport-${data.post_code}-${data.house_number}${
          data.house_addition ? `-${data.house_addition}` : ""
        }.pdf`}
        style={{
          display: "flex",
        }}
      >
        <div className="flex items-center">
          <IoMdDownload size={20} />
          <span className="hidden lg:inline ml-2">Download PDF</span>
        </div>
      </PDFDownloadLink>
    </Button>
  );
};

export default React.memo(EnergyLabelResult);
