import React from "react";
import { Page, Image, Text, View } from "@react-pdf/renderer";
import styles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

interface PropertyOverviewPageProps {
  data: {
    post_code: string;
    house_number: number;
    house_addition: string | null;
    build_year: number;
    inner_surface_area: number;
    house_type: string;
  };
  buildingPhoto?: string;
  map?: string;
}

const PropertyOverviewPage: React.FC<PropertyOverviewPageProps> = ({
  data,
  buildingPhoto = "https://via.placeholder.com/400x300?text=No+Building+Photo",
  map = "https://via.placeholder.com/400x300?text=No+Map+Available",
}) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Uw woning in één oogopslag</Text>

    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Woninggegevens</Text>

      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Huisadres</Text>
        <Text style={styles.detailValue}>
          {data.post_code} {data.house_number}
          {data.house_addition ? ` ${data.house_addition}` : ""}
        </Text>
      </View>

      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Bouwjaar</Text>
        <Text style={styles.detailValue}>{data.build_year}</Text>
      </View>

      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Woonoppervlakte</Text>
        <Text style={styles.detailValue}>{data.inner_surface_area} m²</Text>
      </View>

      <View style={styles.detailRow}>
        <Text style={styles.detailLabel}>Woningtype</Text>
        <Text style={styles.detailValue}>{data.house_type}</Text>
      </View>
    </View>

    <View style={styles.imageContainer}>
      <View style={styles.imageWrapper}>
        <Image src={buildingPhoto} style={styles.buildingPhoto} />
        <View style={styles.imageCaptionContainer}>
          <Text style={styles.imageCaption}>
            {data.post_code} {data.house_number}
            {data.house_addition ? ` ${data.house_addition}` : ""}
          </Text>
        </View>
      </View>
    </View>

    <View style={styles.imageContainer}>
      <View style={styles.imageWrapper}>
        <Image src={map} style={styles.mapImage} />
        <View style={styles.imageCaptionContainer}>
          <Text style={styles.imageCaption}>
            {data.post_code} {data.house_number}
            {data.house_addition ? ` ${data.house_addition}` : ""}
          </Text>
        </View>
      </View>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default PropertyOverviewPage;
