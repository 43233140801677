import React from "react";
import { Page, Image, Text, View } from "@react-pdf/renderer";
import styles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import { AVM } from "../../../../../assets/images/api/APIimages";
import BlobVector from "../../../../../assets/images/BlobVector.png";

interface AVMProps {
  data: {
    street: string;
    housenumber: string;
    houseaddition?: string;
    city: string;
  };
}

const AVMCoverPage: React.FC<AVMProps> = ({ data }) => {
  const address = `${data.street} ${data.housenumber}${
    data.houseaddition || ""
  }`;

  return (
    <Page size="A4" style={styles.mainPage}>
      <View style={styles.logoContainer}>
        <Image src={altumLogo} style={styles.logo} />
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.mainTitle}>Woningwaarde</Text>
        <Text style={styles.mainTitle}>rapport</Text>
        <Text style={styles.subtitle}>
          Gedetailleerde waardebepaling{"\n"}van uw vastgoed
        </Text>
      </View>
      <View style={styles.randomSvgContainer}>
        <Image src={BlobVector} style={styles.randomSvg} />
      </View>
      <View style={styles.houseImageContainer}>
        <Image src={AVM} style={styles.houseImage} />
      </View>
      <View style={styles.addressContainer}>
        <Text style={styles.addressLabel}>WONINGWAARDERAPPORT VOOR</Text>
        <Text style={styles.addressText}>{address.toUpperCase()}</Text>
        <Text style={styles.addressCity}>{data.city.toUpperCase()}</Text>
      </View>
    </Page>
  );
};

export { AVMCoverPage };
