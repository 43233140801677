import React, { FC } from "react";
import { Document } from "@react-pdf/renderer";
import { AVMCoverPage } from "./CoverPage";
import WelcomePage from "./WelcomePage";
import PropertySpecificationsPage from "./PropertySpecificationsPage";
import MarketValuePage from "./MarketValuePage";
import PropertyOverviewPage from "./PropertyOverviewPage";
import GlossaryPage from "./GlossaryPage";

interface PDFReportProps {
  data: {
    postcode: string;
    housenumber: string;
    houseaddition?: string;
    city: string;
    street: string;
    housetype: string;
    buildyear: string;
    inner_surface_area: string;
    outer_surface_area: string;
    volume: string;
    energy_label: string;
    longitude: string;
    latitude: string;
    valuation_date: string;
    price_estimation: string;
    confidence: string;
  };
  buildingPhoto: string;
  map: string;
}

const AVMPDF: FC<PDFReportProps> = ({ data, buildingPhoto, map }) => {
  return (
    <Document>
      <AVMCoverPage data={data} />
      <WelcomePage />
      <PropertyOverviewPage
        buildingPhoto={buildingPhoto}
        map={map}
        data={data}
      />
      <MarketValuePage data={data} />
      <PropertySpecificationsPage data={data} />
      <GlossaryPage />
    </Document>
  );
};

export default AVMPDF;
