import React from "react";
import { Page, Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import { styles } from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";

interface PropertySpecificationsPageProps {
  data: {
    street: string;
    housenumber: string;
    houseaddition?: string;
    city: string;
    housetype: string;
    buildyear: string;
    inner_surface_area: string;
    outer_surface_area: string;
    energy_label: string;
    longitude: string;
    latitude: string;
  };
}

const FireIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M12 23c-4.41 0-8-3.59-8-8 0-3.31 2.73-7.18 5.17-10.12.45-.54 1.21-.54 1.66 0C13.27 7.82 16 11.69 16 15c0 1.1-.9 2-2 2s-2-.9-2-2c0-.73-.39-1.41-.78-2.12-.28-.51-.22-1.14.16-1.58.38-.44 1.02-.54 1.5-.26.54.31 1.03.68 1.48 1.09C15.39 13.42 16 14.65 16 16c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-2.26 1.75-5.14 3.55-7.55C11.04 9.79 10 11.82 10 15c0 1.1.9 2 2 2s2-.9 2-2c0-1.87-.92-3.6-1.94-5.18C11.73 8.99 11 8.07 11 7c0-.54.45-1 1-1s1 .45 1 1c0 .5.3 1.12.71 1.82C14.82 10.78 16 13.06 16 15c0 2.21-1.79 4-4 4z"
      fill="#FF6B00"
    />
  </Svg>
);

const ElectricityIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path d="M7 2v11h3v9l7-12h-4l4-8z" fill="#FFD700" />
  </Svg>
);

const CO2Icon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M4.5 9.5c0 .5.1 1 .2 1.5H3.5c-.3 0-.5-.2-.5-.5V9c0-.3.2-.5.5-.5h1.2c-.1.3-.2.6-.2 1zm1.8-1.5c.3-.4.7-.7 1.2-.9V5.5c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5v1.4c.3-.1.5-.2.8-.2.3 0 .7.1 1 .3zM9 4h1c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5H9c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5zm4 0h1c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5zm4.5 5.5c0-.5-.1-1-.2-1.5h1.2c.3 0 .5.2.5.5v1.5c0 .3-.2.5-.5.5h-1.2c.1-.3.2-.6.2-1zm-1.8 1.5c-.3.4-.7.7-1.2.9v1.6c0 .3.2.5.5.5h1c.3 0 .5-.2.5-.5v-1.4c-.3.1-.5.2-.8.2-.3 0-.7-.1-1-.3zM15 20h-1c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5h1c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5zm-4 0H9c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5h2c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5z"
      fill="#808080"
    />
  </Svg>
);

const PropertySpecificationsPage: React.FC<PropertySpecificationsPageProps> = ({
  data,
}) => {
  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.pageTitle}>Eigendomsspecificaties</Text>

      <Text style={styles.pageText}>
        Belangrijke details over de kenmerken, locatie en energieprofiel van uw
        eigendom
      </Text>

      <View style={styles.energyInfoContainer}>
        <View style={styles.energyInfoLeft}>
          <Text style={styles.energyInfoTitle}>Oppervlakte:</Text>
          <View style={styles.investmentIconContainer}>
            <FireIcon />
            <Text style={styles.energyInfoItem}>
              {" "}
              Woonoppervlakte: {convertToEuFormat(data.inner_surface_area)} m2
            </Text>
          </View>
          <View style={styles.investmentIconContainer}>
            <ElectricityIcon />
            <Text style={styles.energyInfoItem}>
              {" "}
              Kaveloppervlakte: {convertToEuFormat(data.outer_surface_area)} kWh
            </Text>
          </View>
        </View>
        <View style={styles.energyInfoRight}>
          <Text style={styles.energyInfoTitle}>Locatiegegevens:</Text>

          <View style={styles.investmentIconContainer}>
            <FireIcon />
            <Text style={styles.energyInfoItem}>
              {" "}
              <Text style={styles.energyInfoItem}>
                Lengtegraad: {data.longitude}
              </Text>
            </Text>
          </View>
          <View style={styles.investmentIconContainer}>
            <ElectricityIcon />
            <Text style={styles.energyInfoItem}>
              Breedtegraad: {data.latitude}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.energyInfoRight}>
        <Text style={styles.energyInfoTitle}>Huidige energielabel:</Text>
        <View
          style={[
            styles.labelBox,
            styles[`label${data.energy_label}` as keyof typeof styles],
          ]}
        >
          <Text style={styles.labelText}>{data.energy_label}</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
        <Image src={altumLogo} style={styles.footerLogo} />
      </View>
    </Page>
  );
};

export default PropertySpecificationsPage;
