import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { Document, Page, pdfjs } from "react-pdf";
import AVMPDF from "./AVMPDF";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

//this due a Worker not found error
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

interface Props {
  data: any;
  buildingPhoto?: string;
  map?: string;
}

const PDFPreview: React.FC<Props> = ({
  data,
  buildingPhoto = "https://via.placeholder.com/400x300?text=No+Building+Photo",
  map = "https://via.placeholder.com/400x300?text=No+Map+Available",
}) => {
  const [pageCount, setPageCount] = React.useState(1);

  // Ensure we have valid image URLs or fallbacks
  const validBuildingPhoto =
    buildingPhoto ||
    "https://via.placeholder.com/400x300?text=No+Building+Photo";
  const validMap =
    map || "https://via.placeholder.com/400x300?text=No+Map+Available";

  return (
    <BlobProvider
      document={
        <AVMPDF data={data} buildingPhoto={validBuildingPhoto} map={validMap} />
      }
    >
      {({ blob, url, loading, error }) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error.message}</div>;

        return (
          <Document
            file={url}
            onLoadSuccess={(pdf) => setPageCount(pdf.numPages)}
            loading="Loading PDF..."
            error="Failed to load PDF."
          >
            {Array.from({ length: pageCount }, (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                loading="Loading page..."
                error="Failed to load page."
              />
            ))}
          </Document>
        );
      }}
    </BlobProvider>
  );
};

export default PDFPreview;
