import React from "react";
import { Page, Image, Text, View, StyleSheet } from "@react-pdf/renderer";
import baseStyles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import { EnergyMarker } from "./components/EnergyMarker";
import { ScaleValueMarker } from "./components/ScaleValueMarker";

const ENERGY_RATINGS = [
  { label: "G", color: "#E1261C", value: 350 },
  { label: "F", color: "#E63323", value: 335 },
  { label: "E", color: "#F9A11B", value: 290 },
  { label: "D", color: "#FECC00", value: 250 },
  { label: "C", color: "#B0D136", value: 190 },
  { label: "B", color: "#7FB239", value: 160 },
  { label: "A", color: "#4CAF50", value: 105 },
  { label: "A+", color: "#388E3C", value: 75 },
  { label: "A++", color: "#2E7D32", value: 50 },
  { label: "A+++", color: "#1B5E20", value: 30 },
  { label: "A++++", color: "#0A3D00", value: 0 },
] as const;

const styles = {
  ...baseStyles,
  ...StyleSheet.create({
    // energyScaleContainer: {
    //   marginTop: 20,
    //   width: "100%",
    //   padding: "0 40px",
    // },
    energyScaleRow: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 8,
      position: "relative",
    },
    energyScaleBar: {
      height: 40,
      flex: 1,
      marginLeft: 10,
      marginRight: 10,
    },
    energyScaleValue: {
      width: 50,
      fontSize: 12,
      textAlign: "right",
    },
    energyScaleLegend: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
      marginBottom: 40,
    },
    legendItem: {
      flexDirection: "row",
      alignItems: "center",
      gap: 2,
      marginHorizontal: 15,
    },
    legendDot: {
      width: 10,
      height: 10,
      borderRadius: 5,
      marginRight: 8,
    },
    legendText: {
      fontSize: 12,

      color: "#666666",
    },

    energyScaleContainer: {
      position: "relative",
      marginVertical: 10,
      flexDirection: "column",
      gap: 2,
      marginTop: 10,
    },
    energyBar: {
      position: "absolute",
      top: 0,
      flexDirection: "row",
      height: 30,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      alignItems: "center",
      paddingHorizontal: 10,
      marginVertical: 1,
    },
    energyLabel: {
      color: "white",
      fontSize: 12,
      fontWeight: "bold",

      width: 40,
    },
    energyValue: {
      color: "white",
      fontSize: 10,

      marginLeft: 10,
    },
    energyScaleCaption: {
      fontSize: 10,

      textAlign: "center",
      marginTop: 5,
    },
    scaleCaption: {
      fontSize: 10,
      fontWeight: "bold",
      textAlign: "left",
      marginTop: 45,
      color: "#333",
    },
    markerContainer: {
      position: "absolute",
      alignItems: "center",
    },
    markerCircle: {
      width: 24,
      height: 24,
      borderRadius: 12,
    },
    markerInnerCircle: {
      position: "absolute",
      top: 9,
      left: 9,
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: "white",
    },
    markerLine: {
      width: 2,
      height: 36,
      marginTop: -12,
    },
    markerArrow: {
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: 4,
      borderTopWidth: 0,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
    },
  }),
};

interface EnergyLabelPageProps {
  data: {
    definitive_energy_label: string;
    definitive_energy_label_type: string;
    definitive_energy_label_validity_date: string;
    current_estimated_energy_label: string;
    current_estimated_BENG2_score: number;
  };
}

const EnergyLabelPage: React.FC<EnergyLabelPageProps> = ({ data }) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Energielabel en BENG 2 score</Text>

    <Text style={styles.pageText}>
      De NTA 8800 is de bepalingsmethode voor de energieprestatie van gebouwen.
      BENG 2 geeft aan hoeveel primair fossiele energie wordt gebruikt voor
      gebouwgebonden functies: verwarming, koeling, warm water en hulpenergie.
      Dit wordt uitgedrukt in een score met primair fossiel energiegebruik
      (kWh/m² jaar).
    </Text>
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        height: "60%",
      }}
    >
      <View style={styles.energyScaleContainer}>
        {ENERGY_RATINGS.map((rating, index) => (
          <View
            key={index}
            style={[
              styles.energyBar,
              {
                backgroundColor: rating.color,
                width: `${100 / ENERGY_RATINGS.length + 5}%`,
                left: `${(index / ENERGY_RATINGS.length) * 100}%`,
                zIndex: ENERGY_RATINGS.length - index,
              },
            ]}
          >
            <Text style={styles.energyLabel}>{rating.label}</Text>
          </View>
        ))}
        {ENERGY_RATINGS.map(
          (rating, index) =>
            rating.label === data.current_estimated_energy_label && (
              <EnergyMarker
                key={`current-marker-${index}`}
                color="#00BCD4"
                position={{
                  left: `${((index + 0.5) / ENERGY_RATINGS.length) * 100}%`,
                }}
              />
            ),
        )}
        {ENERGY_RATINGS.map(
          (rating, index) =>
            rating.label === data.definitive_energy_label && (
              <EnergyMarker
                key={`definitive-marker-${index}`}
                color="#FF6B00"
                position={{
                  left: `${((index + 0.7) / ENERGY_RATINGS.length) * 100}%`,
                }}
              />
            ),
        )}
        {ENERGY_RATINGS.map((rating, index) => (
          <ScaleValueMarker
            key={`scale-${index}`}
            value={rating.value}
            position={{
              left: `${((index + 0.5) / ENERGY_RATINGS.length) * 100}%`,
            }}
          />
        ))}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            top: 100,
          }}
        >
          <Text style={{ fontWeight: "bold", fontSize: 8 }}> BENG 2 </Text>
          <Text style={styles.legendText}>
            {" "}
            (primaire fossiele energie) in kWh/m²
          </Text>
        </View>
      </View>

      <View style={styles.energyScaleLegend}>
        <View style={styles.legendItem}>
          <EnergyMarker
            color="#00BCD4"
            position={{
              left: "",
              top: -10,
            }}
          />
          <Text style={styles.legendText}>Huidig energielabel</Text>
        </View>
        <View style={styles.legendItem}>
          <EnergyMarker
            color="#FF6B00"
            position={{
              left: "",
              top: -10,
            }}
          />
          <Text style={styles.legendText}>Definitief energielabel</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Certificering</Text>
        <View style={styles.detailRow}>
          <View style={styles.detailGrid}>
            <Text style={styles.detailLabel}>BENG 2 score</Text>
            <Text style={styles.detailLabel}>Type label</Text>
            <Text style={styles.detailLabel}>Geldig tot</Text>
            <Text style={styles.detailLabel}>Energielabel norm</Text>
          </View>

          <View style={styles.detailGrid}>
            <Text style={styles.detailValue}>
              {data.current_estimated_BENG2_score}
            </Text>
            <Text style={styles.detailValue}>
              {data.definitive_energy_label_type}
            </Text>
            <Text style={styles.detailValue}>
              {data.definitive_energy_label_validity_date}
            </Text>
            <Text style={styles.detailValue}>
              Berekeningsmethode Definitief Energielabel, versie 1.2, 16
              september 2014
            </Text>
          </View>
        </View>
      </View>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default EnergyLabelPage;
