import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

const WelcomePage: React.FC = () => {
  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.pageTitle}>Welkom bij uw Woningwaarderapport</Text>

      <Text
        style={{
          ...styles.text,
          marginTop: 30,
          fontSize: 14,
          lineHeight: 1.6,
        }}
      >
        Welkom bij uw uitgebreide eigendomswaarderingsrapport. Met behulp van
        state-of-the-art kunstmatige intelligentie en real-time marktgegevens
        hebben we een grondige analyse uitgevoerd van de huidige marktwaarde van
        uw eigendom. Onze beoordeling combineert historische transactiegegevens
        met gedetailleerde kenmerken van het eigendom om u een accurate, op
        gegevens gebaseerde waardering te bieden die de huidige dynamische
        Nederlandse vastgoedmarkt weerspiegelt.
      </Text>

      <Text
        style={{
          ...styles.text,
          marginTop: 20,
          fontSize: 14,
          lineHeight: 1.6,
        }}
      >
        Dit rapport breekt de belangrijkste factoren af die de waarde van uw
        eigendom beïnvloeden, waardoor u duidelijk inzicht krijgt in hoe
        verschillende elementen bijdragen aan de algehele beoordeling. Van
        locatievoordelen tot eigendomsspecifieke kenmerken, elk onderdeel is
        zorgvuldig geanalyseerd om te zorgen voor een nauwkeurige waardering
        waar u op kunt vertrouwen voor uw vastgoedbeslissingen
      </Text>

      <Text
        style={{
          ...styles.text,
          marginTop: 20,
          fontSize: 14,
          lineHeight: 1.6,
        }}
      >
        Laten we verkennen wat uw eigendom uniek maakt op de huidige markt
      </Text>

      <View style={{ marginTop: 60 }}>
        <Text style={{ ...styles.subHeader, fontSize: 16, color: "#333333" }}>
          Algemene voorwaarden
        </Text>
        <Text
          style={{
            ...styles.text,
            fontSize: 12,
            lineHeight: 1.5,
            color: "#666666",
          }}
        >
          De resultaten van dit rapport moeten als indicatief worden beschouwd.
          Altum AI doet er alles aan om de juistheid van de informatie te
          waarborgen, maar kan de nauwkeurigheid niet garanderen. Altum AI is
          niet aansprakelijk voor enige schade (direct of indirect) die
          voortvloeit uit het gebruik van de gegevens in het Woningwaarderapport
        </Text>
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerText}>
          PRODUCT VAN ALTUM AI | © {new Date().getFullYear()}
        </Text>
        <Image src={altumLogo} style={[styles.footerLogo, { width: 100 }]} />
      </View>
    </Page>
  );
};

export default WelcomePage;
