type Measure = {
  after: {
    desc: string;
    value: number;
  };
  investment: number;
  // ... other properties as needed
};

export const translationMap: { [key: string]: string } = {
  wall_insulation: "Gevelisolatie",
  bedroom_windows: "Slaapkamer Ramen",
  floor_insulation: "Vloerisolatie",
  living_room_windows: "Isolatie woonkamer ramen",
  roof_insulation: "Dakisolatie",
  ventilation: "Ventilatie",
  solar_panels: "Zonnepanelen",
  instalation: "Installatie",
  shower: "Warmte Terug Winning Douche",
};

function camelCaseToReadable(str: string): string {
  return str
    .replace(/_/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function computeMeasuresList(
  measures: { [key: string]: Measure },
  convertToEuFormat: (value: number | string) => number | string,
): string {
  return Object.entries(measures)
    .filter(
      ([key, measure]: [string, Measure]) =>
        measure.investment && measure.investment > 0,
    )
    .map(([key, measure]: [string, Measure]) => {
      const translatedKey = translationMap[key] || key;
      return `${measure.after.desc} ${camelCaseToReadable(
        translatedKey,
      )} (investering : €${convertToEuFormat(measure.investment)})`;
    })
    .join(", ");
}

type MeasureType =
  | "wall_insulation"
  | "floor_insulation"
  | "roof_insulation"
  | "living_room_windows"
  | "bedroom_windows"
  | "ventilation"
  | "shower"
  | "installation"
  | "solar_panels";

export const getMeasureDescription = (
  type: MeasureType,
  value: number,
): string => {
  const baseDescriptions = {
    0: "Geen",
    1: "Matig/na-isolatie",
    2: "Goed",
    3: "Zeer goed",
  };

  const ventilationDescriptions = {
    0: "Natuurlijke ventilatie",
    1: "Mechanische ventilatie",
    2: "Gebalanceerde ventilatie",
    3: "Decentrale mechanische ventilatie",
    4: "Vraaggestuurde ventilatie",
  };

  const windowDescriptions = {
    0: "Enkel glas",
    1: "Dubbel glas",
    2: "HR++ glas",
    3: "Drievoudig glas",
  };

  const showerDescriptions = {
    0: "Geen douche WTW",
    1: "Douche WTW",
  };

  const installationDescriptions = {
    0: "Lokaal gasverw.+geiser",
    1: "VR-ketel+geiser",
    2: "CR-combi",
    3: "VR-combi",
    4: "HR-combi",
    5: "VR-combi+zonneboiler",
    6: "HR-combi+zonneboiler",
    7: "WP bodem combi",
    8: "WP lucht combi",
    9: "HRe-ketel (micro WKK)",
    10: "Stadsverwarming",
    11: "Biomassaketel",
    12: "Hybride warmtepomp",
    13: "Collectieve warmtepomp",
  };

  switch (type) {
    case "ventilation":
      return (
        ventilationDescriptions[
          value as keyof typeof ventilationDescriptions
        ] || "Onbekend"
      );

    case "living_room_windows":
    case "bedroom_windows":
      return (
        windowDescriptions[value as keyof typeof windowDescriptions] ||
        "Onbekend"
      );

    case "shower":
      return (
        showerDescriptions[value as keyof typeof showerDescriptions] ||
        "Onbekend"
      );

    case "installation":
      return (
        installationDescriptions[
          value as keyof typeof installationDescriptions
        ] || "Onbekend"
      );

    case "wall_insulation":
    case "floor_insulation":
    case "roof_insulation":
    case "solar_panels":
      return (
        baseDescriptions[value as keyof typeof baseDescriptions] || "Onbekend"
      );

    default:
      return "Onbekend";
  }
};

// Usage example:
// getMeasureDescription('wall_insulation', 0) // returns "Geen"
// getMeasureDescription('ventilation', 1) // returns "Mechanische ventilatie"
// getMeasureDescription('installation', 4) // returns "HR-combi"
