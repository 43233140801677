import React from "react";
import { Page, Image, Text, View } from "@react-pdf/renderer";
import styles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import { EnergyLabel } from "../../../../../assets/images/api/APIimages";
import BlobVector from "../../../../../assets/images/BlobVector.png";

interface CoverPageProps {
  data: {
    post_code: string;
    house_number: number;
    house_addition: string | null;
  };
}

const CoverPage: React.FC<CoverPageProps> = ({ data }) => (
  <Page size="A4" style={styles.mainPage}>
    <View style={styles.logoContainer}>
      <Image src={altumLogo} style={styles.logo} />
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.mainTitle}>NTA 8800</Text>
      <Text style={styles.mainTitle}>rapport</Text>
      <Text style={styles.subtitle}>
        Uitgebreide inzichten in de{"\n"}energieprestaties van uw gebouw.
      </Text>
    </View>
    <View style={styles.randomSvgContainer}>
      <Image src={BlobVector} style={styles.randomSvg} />
    </View>

    <View style={styles.addressContainer}>
      <Text style={styles.addressLabel}>NTA 8800 RAPPORT VOOR</Text>
      <Text style={styles.addressText}>
        {data.post_code} {data.house_number}
        {data.house_addition ? ` ${data.house_addition}` : ""}
      </Text>
    </View>
    <View style={styles.houseImageContainer_2}>
      <Image src={EnergyLabel} style={styles.houseImage} />
    </View>
  </Page>
);

export default CoverPage;
