import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import { styles } from "./styles";

const GlossaryPage: React.FC = () => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.pageTitle}>Verklarende woordenlijst</Text>

        <View style={styles.glossaryItem}>
          <Text style={styles.glossaryTerm}>Woonoppervlakte</Text>
          <Text style={styles.glossaryDefinition}>
            Woonoppervlakte: De totale bruikbare vloeroppervlakte binnen de
            muren van een eigendom, doorgaans gemeten in vierkante meters (m²).
          </Text>
        </View>

        <View style={styles.glossaryItem}>
          <Text style={styles.glossaryTerm}>Oppervlakte</Text>
          <Text style={styles.glossaryDefinition}>
            De totale landoppervlakte van het eigendom, inclusief zowel bebouwde
            als onbebouwde oppervlakte, gemeten in vierkante meters (m²).
          </Text>
        </View>

        <View style={styles.glossaryItem}>
          <Text style={styles.glossaryTerm}>Marktwaarde</Text>
          <Text style={styles.glossaryDefinition}>
            De geschatte prijs waarop een eigendom zou verkopen in de huidige
            markt
          </Text>
        </View>

        <View style={styles.glossaryItem}>
          <Text style={styles.glossaryTerm}>Betrouwbaarheidsinterval</Text>
          <Text style={styles.glossaryDefinition}>
            Een statistisch bereik dat aangeeft hoe waarschijnlijk het is dat de
            werkelijke marktwaarde binnen het gespecificeerde prijsbereik valt.
            Een hoger betrouwbaarheidspercentage duidt op een betrouwbaardere
            waardering.
          </Text>
        </View>

        <View style={styles.glossaryItem}>
          <Text style={styles.glossaryTerm}>Energielabel</Text>
          <Text style={styles.glossaryDefinition}>
            Een beoordelingssysteem (A t/m G) dat de energie-efficiëntie van een
            gebouw weergeeft. Dit kan invloed hebben op de waarde van het pand
            en toekomstige energiekosten.
          </Text>
        </View>

        <View style={styles.glossaryItem}>
          <Text style={styles.glossaryTerm}>Taxatiedatum</Text>
          <Text style={styles.glossaryDefinition}>
            De specifieke datum waarvoor de waarde van het pand is berekend.
            Marktomstandigheden en vastgoedwaarden kunnen in de loop van de tijd
            variëren.
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>
          PRODUCT VAN ALTUM AI | {new Date().getFullYear()}
        </Text>
        <Image src={altumLogo} style={[styles.footerLogo, { width: 100 }]} />
      </View>
    </Page>
  );
};

export default GlossaryPage;
