import React, { FC } from "react";
import { Document } from "@react-pdf/renderer";
import WelcomePage from "./WelcomePage";
import PropertyOverviewPage from "./PropertyOverviewPage";
import EnergyLabelPage from "./EnergyLabelPage";
import SustainabilityMeasuresPage from "./SustainabilityMeasuresPage";
import GlossaryPage from "./GlossaryPage";
import CoverPage from "./CoverPage";

interface PDFReportProps {
  data: {
    post_code: string;
    house_number: number;
    house_addition: string | null;
    build_year: number;
    inner_surface_area: number;
    house_type: string;
    installation: number;
    wall_insulation: number;
    roof_insulation: number;
    floor_insulation: number;
    living_room_windows: number;
    ventilation: number;
    bedroom_windows: number;
    shower: number;
    solar_panels: number;
    solarpanel_watt_peak: number;
    CO2: number;
    definitive_energy_label: string;
    definitive_energy_label_type: string;
    definitive_energy_label_validity_date: string;
    current_estimated_energy_label: string;
    current_estimated_BENG2_score: number;
    estimated_gas_usage: number;
    estimated_energy_usage: number;
  };
  buildingPhoto: string;
  map: string;
}

const EnergyLabelPDF: FC<PDFReportProps> = ({ data, buildingPhoto, map }) => {
  return (
    <Document>
      <CoverPage data={data} />
      <WelcomePage />
      <PropertyOverviewPage
        data={data}
        buildingPhoto={buildingPhoto}
        map={map}
      />
      <EnergyLabelPage data={data} />
      <SustainabilityMeasuresPage data={data} />
      <GlossaryPage />
    </Document>
  );
};

export default EnergyLabelPDF;
