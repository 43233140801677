import React from "react";
import { View, StyleSheet, Path, Svg } from "@react-pdf/renderer";

interface EnergyMarkerProps {
  color: string;
  position: {
    left: string;
    top?: number;
  };
}

const styles = StyleSheet.create({
  markerContainer: {
    position: "absolute",
    alignItems: "center",
    transform: "translateX(-12px)",
  },
});

export const EnergyMarker: React.FC<EnergyMarkerProps> = ({
  color,
  position,
}) => {
  return (
    <View
      style={[
        styles.markerContainer,
        {
          left: position.left,
          top: position.top ?? -24,
        },
      ]}
    >
      <Svg width={24} height={24} viewBox="0 0 24 48">
        <Path
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
          fill={color}
        />
        <Path
          d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
          fill="white"
        />
        <Path d="M11 12H13V48H11V12Z" fill={color} />
        <Path d="M12 48L10 44H14L12 48Z" fill={color} />
      </Svg>
    </View>
  );
};
