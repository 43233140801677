import React, { ReactNode } from "react";
import ApiFormContainer from "../../components/ApiFormContainer";
import { AVM } from "../../../../../assets/images/api/APIimages";

const progress = ["Woning identificatie", "Resultaat"];

const AVMContainer: React.FC<{
  children: ReactNode;
  page: number;
}> = ({ children, page }) => {
  return (
    <ApiFormContainer
      page={page}
      title="Woningwaarde model"
      subtitle="Bereken de geschatte marktwaarde van een woning op basis van objectkenmerken en referentiewoningen in de omgeving."
      progress={progress}
      resultSelector={(state) => state.avm.result}
      link="https://docs.altum.ai/apis/automated-valuation-model-api"
      image={AVM}
    >
      {children}
    </ApiFormContainer>
  );
};

export default AVMContainer;
