import React from "react";
import { View, StyleSheet, Text, Svg, Path } from "@react-pdf/renderer";

interface ScaleValueMarkerProps {
  value: number;
  position: {
    left: string;
    top?: number;
  };
}

const styles = StyleSheet.create({
  markerContainer: {
    position: "absolute",
    alignItems: "center",
    transform: "translateX(-15px)",
  },
  valueText: {
    fontSize: 8,
  },
});

export const ScaleValueMarker: React.FC<ScaleValueMarkerProps> = ({
  value,
  position,
}) => {
  return (
    <View
      style={[
        styles.markerContainer,
        {
          left: position.left,
          top: position.top ?? 30,
        },
      ]}
    >
      <Svg width={30} height={30} viewBox="0 0 30 30">
        <Path d="M10 0 V20 H20" stroke="black" strokeWidth="1" />
      </Svg>
      <Text style={styles.valueText}>{value}</Text>
    </View>
  );
};
