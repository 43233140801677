import { FC, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useFormContext } from "../../components/FormContext";
import PageNav from "../../components/PageNav";
import IdentifyProperty from "../components/IdentifyProperty";
import { Redirect } from "react-router-dom";
import { postAvmDetails } from "../../../../../redux/actions/avmActions";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const AVMForm: FC<Props> = ({ page, setPage }) => {
  const { formValues, validate, fetchAddressData, setPostalAddress } =
    useFormContext();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.avm);
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const validatePostCode = useCallback(() => {
    if (!formValues) return false;
    const postCodeErrors = validate(
      {
        postcode: formValues.postcode,
        housenumber: formValues.housenumber,
      },
      {
        postcode: {
          minLength: 6,
          maxLength: 6,
          regex: /^[0-9]{4}[A-Za-z]{2}$/i,
          required: true,
        },
        housenumber: {
          minLength: 1,
          maxLength: 6,
          required: true,
        },
      },
    );

    return !postCodeErrors.postcode && !postCodeErrors.housenumber;
  }, [formValues, validate]);

  const handleFormSubmit = useCallback(() => {
    const updatedData = {
      postcode: formValues.postcode?.split(" ").join("").toUpperCase() || "",
      housenumber: formValues.housenumber,
      houseaddition: formValues.houseaddition,
      valuation_date: formValues.valuation_date,
      innersurfacearea: formValues.innersurfacearea,
      energylabel: formValues.energylabel,
      outersurfacearea: formValues.outersurfacearea,
    };

    apiKey && dispatch(postAvmDetails({ formData: updatedData, apiKey }));
  }, [apiKey, dispatch, formValues]);

  useEffect(() => {
    if (formValues.postcode && formValues.housenumber) {
      setPostalAddress(
        `${formValues.postcode}-${formValues.housenumber}-${
          formValues.houseaddition ? formValues.houseaddition : ""
        }`,
      );
    }
  }, [formValues, setPostalAddress]);

  const next = useCallback(() => {
    if (page === 1 && !validatePostCode()) return;
    if (page === 1 && validatePostCode()) {
      fetchAddressData();
      handleFormSubmit();
    }
    setPage((prevPage) => prevPage + 1);
  }, [page, validatePostCode, fetchAddressData, handleFormSubmit, setPage]);

  if (loading) {
    return <Redirect to="/avm-result" />;
  }

  return (
    <>
      {page === 1 && <IdentifyProperty />}
      {page < 2 && (
        <PageNav
          page={page}
          disabled={Object.keys(formValues).length === 0}
          next={next}
          prev={() => setPage((p) => Math.max(p - 1, 1))}
        />
      )}
    </>
  );
};

export default AVMForm;
