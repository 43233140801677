import React, { FC } from "react";
import { Page, Image, Text, View, Path, Svg } from "@react-pdf/renderer";
import styles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

const CheckIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
      fill="#000000"
    />
  </Svg>
);

const BulletPoint: FC<{ children: React.ReactNode }> = ({ children }) => (
  <View style={styles.bulletPointContainer}>
    <View style={styles.bulletIcon}>
      <CheckIcon />
    </View>
    <Text style={styles.pageText}>{children}</Text>
  </View>
);
const WelcomePage: React.FC = () => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>
      Welkom bij uw indicatieve NTA 8800 Energielabel rapport
    </Text>

    <Text style={styles.pageText}>
      Dit rapport biedt een gedetailleerde analyse van de energieprestaties van
      uw woning met behulp van de NTA 8800 Energielabel API, een krachtig
      hulpmiddel om inzicht te krijgen in de huidige of potentiële
      energie-efficiëntie van uw woning te schatten
    </Text>

    <View style={styles.section}>
      <View style={styles.bulletPointContainer}>
        <BulletPoint>
          <Text style={styles.detailValue}> Geschat energielabel:</Text> Door de
          huidige kenmerken van uw woning te analyseren, geeft dit rapport een
          geschat energielabel volgens de NTA 8800-standaard, wat een duidelijk
          beeld geeft van de energieprestaties van uw woning
        </BulletPoint>
      </View>

      <View style={styles.bulletPointContainer}>
        <View style={styles.bulletIcon}>
          <CheckIcon />
        </View>
        <Text style={styles.pageText}>
          <Text style={styles.detailValue}>
            Bestaande energiebesparende maatregelen:
          </Text>{" "}
          Een overzicht van de energiebesparende maatregelen die al in uw woning
          zijn doorgevoerd, met inzicht in de huidige energiezuinige kenmerken
        </Text>
      </View>
    </View>

    <Text style={styles.pageText}>
      Dit rapport helpt u inzicht te krijgen in de energie-efficiëntie van uw
      woning en geeft suggesties om prestaties te optimaliseren en kosten te
      verlagen.
    </Text>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default WelcomePage;
