import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useFormContext } from "../../components/FormContext";
import { Redirect, useHistory } from "react-router-dom";
import RedoButton from "../../components/RedoButtons";
import {
  clearEnergyLabelResults,
  modifyEnergyLabelQueries,
} from "../../../../../redux/actions/energyLabelActions";
import ResultSkeleton from "../../sustainability/components/ResultSkeleton";
import Result from "../components/Result";
import { ResultSummary } from "../components/ResultSummary";

const EnergyLabelResult = () => {
  const { result, loading } = useAppSelector((state) => state.energyLabel);
  const { buildingPhoto, map, setPostalAddress } = useFormContext();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearEnergyLabelResults());
    history.push("/energylabel");
  };

  const modifyResults = () => {
    dispatch(modifyEnergyLabelQueries());
    history.push("/energylabel");
  };

  useEffect(() => {
    const property = result as any;
    if (Object.keys(result).length > 0) {
      setPostalAddress(
        `${property.post_code}-${property.house_number}-${
          property.house_addition || ""
        }`,
      );
    }
  }, [result, setPostalAddress]);

  if (Object.keys(result).length === 0 && !loading) {
    return <Redirect to="/energylabel" />;
  }

  return (
    <>
      {loading ? (
        <ResultSkeleton />
      ) : (
        <>
          <ResultSummary
            property={result}
            buildingPhoto={buildingPhoto}
            map={map}
          />
          <Result property={result} buildingPhoto={buildingPhoto} map={map} />
          <RedoButton modify={modifyResults} clear={clearResults} />
        </>
      )}
    </>
  );
};

export default EnergyLabelResult;
