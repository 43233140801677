import { StyleSheet } from "@react-pdf/renderer";
import baseStyles from "../../sustainability/pdf/styles";

export const styles = StyleSheet.create({
  ...baseStyles,

  // AVM-specific styles
  logoContainer: {
    position: "absolute",
    top: 32,
    left: 40,
  },
  logo: {
    width: 120,
    height: "auto",
  },
  mainTitle: {
    fontSize: 42,

    fontWeight: 700,
    color: "#333333",
    marginBottom: -10,
  },
  subtitle: {
    fontSize: 16,

    fontWeight: 400,
    color: "#666666",
    marginTop: 10,
  },
  houseImageContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "50%",
    backgroundColor: "#F5F9FF",
  },
  houseImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  addressContainer: {
    position: "absolute",
    bottom: "55%",
    left: 40,
  },
  addressLabel: {
    textTransform: "uppercase",
    color: "#FFA500",
    fontSize: 14,
    letterSpacing: 1,
  },
  addressText: {
    fontWeight: 700,
    marginTop: 5,
    fontSize: 24,
    color: "#333333",
    letterSpacing: 1,
  },
  addressCity: {
    fontSize: 18,
    color: "#666666",
    marginTop: 5,
    letterSpacing: 1,
  },
  page: {
    padding: 30,
    position: "relative",
  },
  coverPage: {
    padding: 0,
    position: "relative",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    color: "#00A67E",
    fontWeight: "bold",
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 15,
    color: "#333",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    color: "#666",
    lineHeight: 1.5,
  },
  bold: {
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  valueBox: {
    padding: 15,
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    marginBottom: 20,
  },
  valueAmount: {
    fontSize: 24,
    color: "#00A67E",
    fontWeight: "bold",
    marginBottom: 5,
  },
  valueDate: {
    fontSize: 14,
    color: "#666",
  },
  propertyImage: {
    width: "100%",
    height: 200,
    objectFit: "cover",
    marginBottom: 20,
  },
  map: {
    width: "100%",
    height: 150,
    marginBottom: 20,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    color: "#999",
    fontSize: 10,
  },
  coverImage: {
    width: "100%",
    height: "50%",
    position: "absolute",
    bottom: 0,
  },
  glossaryItem: {
    marginBottom: 15,
  },
  glossaryTerm: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 5,
  },
  glossaryDefinition: {
    fontSize: 12,
    color: "#666",
    lineHeight: 1.4,
  },
  propertyDetails: {
    marginTop: 20,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  detailLabel: {
    fontSize: 12,
    color: "#666",
  },
  detailValue: {
    fontSize: 12,
    color: "#333",
    fontWeight: "bold",
  },
  confidenceInterval: {
    fontSize: 14,
    color: "#666",
    marginTop: 10,
    fontStyle: "italic",
  },
});
