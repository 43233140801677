import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";

// Toast Package
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// ACTIONS
import ReactGA from "react-ga";
import { loadUser } from "./redux/actions/authActions";
import withTracker from "./helpers/withTracker";
import PrivateRoute from "./components/Routing/PrivateRoute";
import Avm from "./pages/Api/usage-pages/avm/form";
import AvmResult from "./pages/Api/usage-pages/avm/result";
import ConditionScoreResult from "./components/apiForms/conditionScore/ConditionScoreResult";
import EnergyInsight from "./components/apiForms/energy-insight/EnergyInsight";
import EnergyInsightResult from "./components/apiForms/energy-insight/EnergyInsightResult";
import ImageLabel from "./components/apiForms/imageLabel/ImageLabel";
import ImageLabelResult from "./components/apiForms/imageLabel/ImageLabelResult";
import Move from "./components/apiForms/move/Move";
import ObjectDataResult from "./components/apiForms/objectData/ObjectDataResult";
import ObjectGeometryResult from "./components/apiForms/objectGeometry/ObjectGeometryResult";
import Reference from "./components/apiForms/reference/Reference";
import ReferenceResult from "./components/apiForms/reference/ReferenceResult";
import Woz from "./components/apiForms/woz/Woz";
import WozResult from "./components/apiForms/woz/WozResult";
import ResetPassword from "./pages/Auth/ResetPassword";
import Wrapper from "./containers/Wrapper";
import useIsomorphicLayoutEffect from "./hooks/useIsomorphicLayoutEffect";
import Onboarding from "./pages/onboarding";
import PrivacyStatement from "./pages/PrivacyTerms/PrivacyStatement";
import TermsConditions from "./pages/PrivacyTerms/TermsConditions";
import Unsubscribe from "./pages/Unsubscribe";
import { useAppDispatch } from "./redux/hooks";
import SuccessPage from "./pages/SuccessPage";
import FailurePage from "./pages/FailurePage";
import MoveResult from "./components/apiForms/move/MoveResult";
import LocationData from "./components/apiForms/location-data/locationData";
import LocationDataResult from "./components/apiForms/location-data/locationDataResult";
import Kadaster from "./components/apiForms/kadaster/Kadaster";
import KadasterResult from "./components/apiForms/kadaster/KadasterResult";
import ConditionScore from "./components/apiForms/conditionScore/ConditionScore";
import ObjectGeometry from "./components/apiForms/objectGeometry/ObjectGeometry";
import ObjectData from "./components/apiForms/objectData/ObjectData";
import SignIn from "./pages/Auth/signin/Signin";
import Signup from "./pages/Auth/signup/Signup";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import VerifyAccount from "./pages/Auth/VerifyAccount";
import DeleteAccount from "./pages/Auth/DeleteAccount";
import ChangePassword from "./pages/Auth/ChangePassword";
import Dashboard from "./pages/Dashboard/startinpagina";
import Analytics from "./pages/Dashboard/analytics/Analytics";
import ApiKey from "./pages/Dashboard/account/components/ApiKey";
import Products from "./pages/Api/Products";
import GetStarted from "./pages/Dashboard/get-started";
import Rebuild from "./components/apiForms/rebuild/Rebuild";
import RebuildResult from "./components/apiForms/rebuild/RebuildResult";
import SolarScan from "./components/apiForms/solar-scan/SolarScan";
import SolarScanResult from "./components/apiForms/solar-scan/SolarScanResult";
import AutoSuggest from "./components/apiForms/autosuggest/AutoSuggest";
import AutoSuggestResult from "./components/apiForms/autosuggest/AutoSuggestResult";
import RentalReference from "./components/apiForms/rental-reference/RentalReference";
import RentalReferenceResult from "./components/apiForms/rental-reference/RentalReferenceResult";
import Sustainabilty from "./pages/Api/usage-pages/sustainability/form";
import SustainabilityResult from "./pages/Api/usage-pages/sustainability/result";
import EnergyClimate from "./components/apiForms/energy-climate/EnergyClimate";
import EnergyClimateResult from "./components/apiForms/energy-climate/EnergyClimateResult";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Subscriptions from "./pages/Dashboard/subscription";
import PropertyDescriptionGenerator from "./pages/property-description";
import PropertyDescriptionForm from "./pages/property-description/PropertyDescriptionForm";
import ChatHistory from "./pages/property-description/ChatHistory";
import Account from "./pages/Dashboard/account";
import WWS from "./pages/Api/usage-pages/wws/form";
import WWWResult from "./pages/Api/usage-pages/wws/result/WWWResult";
import PDFPreview from "./pages/Api/usage-pages/sustainability/pdf/PDFPreview";
import EnergyLabelForm from "./pages/Api/usage-pages/energylabel/form";
import EnergyLabelResultPage from "./pages/Api/usage-pages/energylabel/result";

function App() {
  const dispatch = useAppDispatch();
  useIsomorphicLayoutEffect(() => {
    dispatch(loadUser(dispatch));
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID!);

    window.scrollTo(0, 0);
  }, [dispatch]);
  return (
    <Router>
      <div className="App flex-wrapper">
        <ToastContainer
          position="top-center"
          draggable={false}
          hideProgressBar
          limit={3}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
        />
        <Switch>
          {/* <Route
							exact
							path="/"
							// render={(props) => (
							// 	<Landing {...props} scroll={scrollToPricing} />
							// )} */}
          <Route exact path="/signin" component={withTracker(SignIn)} />
          <Route exact path="/" component={withTracker(Signup)} />
          <Route exact path="/verify-email" component={VerifyEmail} />
          <Route exact path="/onboarding" component={withTracker(Onboarding)} />
          <Route
            exact
            path="/forgot-password"
            component={withTracker(ForgotPassword)}
          />
          <Route
            exact
            path="/users/resetPassword/:resetToken"
            component={withTracker(ResetPassword)}
          />

          <Wrapper>
            <Switch>
              {/* <Route
                  exact
                  path="/signup-confirmation/:id"
                  component={withTracker(SignupConfirmPage)}
                /> */}

              <PrivateRoute
                exact
                path="/property-description"
                component={withTracker(PropertyDescriptionGenerator)}
              />
              <PrivateRoute
                exact
                path="/property-description/history"
                component={withTracker(ChatHistory)}
              />
              <PrivateRoute
                exact
                path="/property-description/generate"
                component={withTracker(PropertyDescriptionForm)}
              />

              <PrivateRoute
                exact
                path="/sustainability"
                component={withTracker(Sustainabilty)}
              />
              <PrivateRoute
                exact
                path="/sustainability-result"
                component={withTracker(SustainabilityResult)}
              />
              <PrivateRoute exact path="/avm" component={withTracker(Avm)} />
              <PrivateRoute
                exact
                path="/avm-result"
                component={withTracker(AvmResult)}
              />
              <PrivateRoute exact path="/woz" component={withTracker(Woz)} />
              <PrivateRoute
                exact
                path="/woz-result"
                component={withTracker(WozResult)}
              />
              <PrivateRoute
                exact
                path="/verhuisdata"
                component={withTracker(Move)}
              />
              <PrivateRoute
                exact
                path="/verhuisdata-result"
                component={withTracker(MoveResult)}
              />
              <PrivateRoute
                path="/success"
                component={withTracker(SuccessPage)}
              />
              <PrivateRoute
                path="/failed"
                component={withTracker(FailurePage)}
              />
              <Route
                exact
                path="/privacy-policy"
                component={withTracker(PrivacyStatement)}
              />
              <Route
                exact
                path="/terms-and-conditions"
                component={withTracker(TermsConditions)}
              />
              <Route
                exact
                path="/users/account-verification/:userId"
                component={withTracker(VerifyAccount)}
              />
              {/* NEW DASHBOARD */}
              <PrivateRoute
                exact
                path="/dashboard/aandeslag"
                component={withTracker(GetStarted)}
              />
              <PrivateRoute
                exact
                path="/dashboard/startpagina"
                component={withTracker(Dashboard)}
              />
              <PrivateRoute
                exact
                path="/dashboard/account"
                component={withTracker(Account)}
              />
              <PrivateRoute
                exact
                path="/dashboard/account/change-password"
                component={withTracker(ChangePassword)}
              />
              <PrivateRoute
                exact
                path="/dashboard/account/deactivate-account"
                component={withTracker(DeleteAccount)}
              />
              <PrivateRoute
                exact
                path="/dashboard/analytics"
                component={withTracker(Analytics)}
              />
              <PrivateRoute
                exact
                path="/dashboard/products"
                component={withTracker(Products)}
              />
              {/* <PrivateRoute
                  exact
                  path="/dashboard/aandeslag"
                  component={withTracker(GetStarted)}
                /> */}
              <PrivateRoute
                exact
                path="/dashboard/abonnement"
                component={withTracker(Subscriptions)}
              />
              <PrivateRoute
                exact
                path="/apikeys"
                component={withTracker(ApiKey)}
              />
              <PrivateRoute
                exact
                path="/location-data"
                component={LocationData}
              />
              <PrivateRoute
                exact
                path="/location-data-result"
                component={withTracker(LocationDataResult)}
              />
              <PrivateRoute
                exact
                path="/energy-insights"
                component={EnergyInsight}
              />
              <PrivateRoute
                exact
                path="/energy-insights-result"
                component={withTracker(EnergyInsightResult)}
              />
              <PrivateRoute
                exact
                path="/referentie"
                component={withTracker(Reference)}
              />
              <PrivateRoute
                exact
                path="/reference-result"
                component={withTracker(ReferenceResult)}
              />

              <Route
                exact
                path="/unsubscribe"
                component={withTracker(Unsubscribe)}
              />
              <PrivateRoute
                exact
                path="/transactie-api"
                component={withTracker(Kadaster)}
              />
              <PrivateRoute
                exact
                path="/transactie-result"
                component={withTracker(KadasterResult)}
              />

              <PrivateRoute
                exact
                path="/foto-labelen/"
                component={withTracker(ImageLabel)}
              />
              <PrivateRoute
                exact
                path="/foto-labelen-result/"
                component={withTracker(ImageLabelResult)}
              />
              <PrivateRoute
                exact
                path="/conditie-score/"
                component={withTracker(ConditionScore)}
              />
              <PrivateRoute
                exact
                path="/conditie-score-result"
                component={withTracker(ConditionScoreResult)}
              />
              <PrivateRoute
                exact
                path="/object-geometry"
                component={withTracker(ObjectGeometry)}
              />
              <PrivateRoute
                exact
                path="/object-geometry-result"
                component={withTracker(ObjectGeometryResult)}
              />
              <PrivateRoute
                exact
                path="/energylabel"
                component={withTracker(EnergyLabelForm)}
              />
              <PrivateRoute
                exact
                path="/energylabel-result"
                component={withTracker(EnergyLabelResultPage)}
              />

              <PrivateRoute exact path="/objectdata" component={ObjectData} />
              <PrivateRoute
                exact
                path="/objectdata-result"
                component={ObjectDataResult}
              />
              <PrivateRoute exact path="/rebuild" component={Rebuild} />
              <PrivateRoute
                exact
                path="/rebuild-result"
                component={RebuildResult}
              />
              <PrivateRoute exact path="/solarscan" component={SolarScan} />
              <PrivateRoute
                exact
                path="/solarscan-result"
                component={SolarScanResult}
              />
              <PrivateRoute exact path="/autosuggest" component={AutoSuggest} />
              <PrivateRoute
                exact
                path="/autosuggest-result"
                component={AutoSuggestResult}
              />
              <PrivateRoute
                exact
                path="/rentalreference"
                component={RentalReference}
              />
              <PrivateRoute
                exact
                path="/rentalreference-result"
                component={RentalReferenceResult}
              />
              <PrivateRoute
                exact
                path="/energy-climate"
                component={EnergyClimate}
              />
              <PrivateRoute
                exact
                path="/energy-climate-result"
                component={EnergyClimateResult}
              />
              <PrivateRoute exact path="/wws" component={withTracker(WWS)} />
              <PrivateRoute
                exact
                path="/wws-result"
                component={withTracker(WWWResult)}
              />
              <Route path="/pdf-preview" component={PDFPreview} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </Wrapper>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
