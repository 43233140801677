import React from "react";
import { Page, Image, Text, View } from "@react-pdf/renderer";
import styles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";

const GlossaryPage: React.FC = () => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Verklarende woordenlijst</Text>

    <View style={styles.glossaryContainer}>
      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• NTA 8800:</Text>
        <Text style={styles.glossaryDefinition}>
          De Nederlandse norm voor het bepalen van de energieprestatie van
          gebouwen.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• Energielabel:</Text>
        <Text style={styles.glossaryDefinition}>
          Een beoordelingssysteem (A tot G) dat de energie-efficiëntie van een
          gebouw aangeeft.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• BENG 2-score:</Text>
        <Text style={styles.glossaryDefinition}>
          BENG staat voor 'bijna energieneutrale gebouwen'. Maximale primaire
          fossiele energiegebruik in kWh per vierkante meter per jaar.. This
          indicator is the sum of all energy related aspects of a building.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• kWh:</Text>
        <Text style={styles.glossaryDefinition}>
          Kilowattuur, een eenheid van energie die vaak wordt gebruikt voor
          elektriciteitsfacturering
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• m³:</Text>
        <Text style={styles.glossaryDefinition}>
          Kubieke meter, een eenheid die vaak wordt gebruikt voor het meten van
          gasverbruik
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• CO2-uitstoot:</Text>
        <Text style={styles.glossaryDefinition}>
          De hoeveelheid koolstofdioxide die in de atmosfeer wordt uitgestoten,
          vaak als gevolg van energieverbruik
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• HR-combi:</Text>
        <Text style={styles.glossaryDefinition}>
          Hoogrendement combiketel voor centrale verwarming en warm water.
        </Text>
      </View>

      <View style={styles.glossaryItem}>
        <Text style={styles.glossaryTerm}>• WTW:</Text>
        <Text style={styles.glossaryDefinition}>
          Warmteterugwinningssysteem
        </Text>
      </View>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default GlossaryPage;
