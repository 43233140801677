import React from "react";
import { Page, Image, Text, View, Path, Svg } from "@react-pdf/renderer";
import styles from "../../sustainability/pdf/styles";
import altumLogo from "../../../../../assets/images/Logo-AltumAI.png";
import convertToEuFormat from "../../../../../helpers/convertToEuFormat";
import {
  getMeasureImages,
  translateMeasure,
} from "../../sustainability/pdf/utils/measureUtils";
import { getMeasureDescription } from "../../../../../helpers/measureHelpers";

interface SustainabilityMeasuresPageProps {
  data: {
    estimated_gas_usage: number;
    estimated_energy_usage: number;
    CO2: number;
    wall_insulation: number;
    roof_insulation: number;
    floor_insulation: number;
    living_room_windows: number;
    installation: number;
    ventilation: number;
    bedroom_windows: number;
    shower: number;
    solar_panels: number;
    solarpanel_watt_peak: number;
  };
}
const FireIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M12 23c-4.41 0-8-3.59-8-8 0-3.31 2.73-7.18 5.17-10.12.45-.54 1.21-.54 1.66 0C13.27 7.82 16 11.69 16 15c0 1.1-.9 2-2 2s-2-.9-2-2c0-.73-.39-1.41-.78-2.12-.28-.51-.22-1.14.16-1.58.38-.44 1.02-.54 1.5-.26.54.31 1.03.68 1.48 1.09C15.39 13.42 16 14.65 16 16c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-2.26 1.75-5.14 3.55-7.55C11.04 9.79 10 11.82 10 15c0 1.1.9 2 2 2s2-.9 2-2c0-1.87-.92-3.6-1.94-5.18C11.73 8.99 11 8.07 11 7c0-.54.45-1 1-1s1 .45 1 1c0 .5.3 1.12.71 1.82C14.82 10.78 16 13.06 16 15c0 2.21-1.79 4-4 4z"
      fill="#FF6B00"
    />
  </Svg>
);

const ElectricityIcon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path d="M7 2v11h3v9l7-12h-4l4-8z" fill="#FFD700" />
  </Svg>
);

const CO2Icon = () => (
  <Svg width="12" height="12" viewBox="0 0 24 24">
    <Path
      d="M4.5 9.5c0 .5.1 1 .2 1.5H3.5c-.3 0-.5-.2-.5-.5V9c0-.3.2-.5.5-.5h1.2c-.1.3-.2.6-.2 1zm1.8-1.5c.3-.4.7-.7 1.2-.9V5.5c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5v1.4c.3-.1.5-.2.8-.2.3 0 .7.1 1 .3zM9 4h1c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5H9c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5zm4 0h1c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5zm4.5 5.5c0-.5-.1-1-.2-1.5h1.2c.3 0 .5.2.5.5v1.5c0 .3-.2.5-.5.5h-1.2c.1-.3.2-.6.2-1zm-1.8 1.5c-.3.4-.7.7-1.2.9v1.6c0 .3.2.5.5.5h1c.3 0 .5-.2.5-.5v-1.4c-.3.1-.5.2-.8.2-.3 0-.7-.1-1-.3zM15 20h-1c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5h1c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5zm-4 0H9c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5h2c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5z"
      fill="#808080"
    />
  </Svg>
);

const SustainabilityMeasuresPage: React.FC<SustainabilityMeasuresPageProps> = ({
  data,
}) => (
  <Page size="A4" style={styles.page}>
    <Text style={styles.pageTitle}>Duurzaamheidsmaatregelen</Text>

    <Text style={styles.pageText}>
      Dit gedeelte toont de energiebesparende maatregelen die al in uw woning
      zijn geïmplementeerd, en biedt een duidelijk overzicht van uw huidige
      inspanningen op het gebied van duurzaamheid en energie-efficiëntie.
    </Text>

    <View style={styles.energyInfoContainer}>
      <View style={styles.energyInfoLeft}>
        <Text style={styles.energyInfoTitle}>Geschat energieverbruik:</Text>
        <View style={styles.investmentIconContainer}>
          <FireIcon />
          <Text style={styles.energyInfoItem}>
            Gas per jaar: {convertToEuFormat(data.estimated_gas_usage)} m3
          </Text>
        </View>
        <View style={styles.investmentIconContainer}>
          <ElectricityIcon />
          <Text style={styles.energyInfoItem}>
            {" "}
            Elektriciteit per jaar:{" "}
            {convertToEuFormat(data.estimated_energy_usage)} kWh
          </Text>
        </View>
        <View style={styles.investmentIconContainer}>
          <CO2Icon />
          <Text style={styles.energyInfoItem}>
            {" "}
            Totaal CO2 emissie: {convertToEuFormat(data.CO2)} kg
          </Text>
        </View>
      </View>
    </View>

    <View style={styles.measuresTable}>
      <View style={styles.tableHeader}>
        <Text style={styles.tableHeaderText}>Maatregel</Text>
        <Text style={styles.tableHeaderText}>Huidig</Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("floor_insulation")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("floor_insulation")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("floor_insulation", data.floor_insulation)}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("roof_insulation")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("roof_insulation")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("roof_insulation", data.roof_insulation)}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("wall_insulation")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("wall_insulation")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("wall_insulation", data.wall_insulation)}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("windows")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>{translateMeasure("windows")}</Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("bedroom_windows", data.bedroom_windows)}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("living_room_windows")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("living_room_windows")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription(
            "living_room_windows",
            data.living_room_windows,
          )}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("solar_panels")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("solar_panels")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("solar_panels", data.solar_panels)}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("instalation")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("instalation")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("installation", data.installation)}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image src={getMeasureImages("shower")} style={styles.measureImage} />
          <Text style={styles.tableCell}>{translateMeasure("shower")}</Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("shower", data.shower)}
        </Text>
      </View>

      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Image
            src={getMeasureImages("ventilation")}
            style={styles.measureImage}
          />
          <Text style={styles.tableCell}>
            {translateMeasure("ventilation")}
          </Text>
        </View>
        <Text style={styles.tableCell}>
          {getMeasureDescription("ventilation", data.ventilation)}
        </Text>
      </View>
    </View>

    <View style={styles.footer}>
      <Text style={styles.footerText}>PRODUCT VAN ALTUM AI | © 2024</Text>
      <Image src={altumLogo} style={styles.footerLogo} />
    </View>
  </Page>
);

export default SustainabilityMeasuresPage;
